import React from 'react';
import { Box, Typography, Link } from '@mui/material';

const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{
        bgcolor: 'primary.main', // Same color as header
        color: 'black',
        p: 2,
        mt: 'auto',
        textAlign: 'center',
      }}
    >
      <Typography
        variant="body2"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          gap: 3,
        }}
      >
        <Link href="/kontakt" color="inherit">Kontakt</Link>
        <Link href="/impressum" color="inherit">Impressum</Link>
        <Link href="/datenschutzerklarung" color="inherit">Datenschutzerklärung</Link>
      </Typography>
    </Box>
  );
};

export default Footer;
