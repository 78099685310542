// src/Impressum.js
import React from 'react';
import { Container, Typography, Box } from '@mui/material';

const Impressum = () => {
  return (
    <Container style={{ padding: '40px' }}>
      <Typography variant="h4" align="center" gutterBottom>
        Impressum
      </Typography>

      <Box style={{ marginBottom: '20px' }}>
        <Typography variant="h6">Angaben gemäß § 5 TMG</Typography>
        <Typography>
          Heilpraxis Sylwia Spiller, <br />
          Sylwia Spiller, Heilpraktikerin <br />
          Riemeisterstraße 39 a <br /> 14169 Berlin
        </Typography>
      </Box>

      <Box style={{ marginBottom: '20px' }}>
        <Typography variant="h6">Kontakt</Typography>
        <Typography>
          Telefon: +49 15560759736 <br />
          E-Mail: kontakt@heilpraxis-spiller.de <br />
          Website: www.heilpraxis-spiller.de
        </Typography>
      </Box>

      <Box style={{ marginBottom: '20px' }}>
        <Typography variant="h6">Berufsbezeichnung und Aufsichtsbehörde</Typography>
        <Typography>
          Berufsbezeichnung: Heilpraktiker <br />
          Zuständige Aufsichtsbehörde: <br />Landesamt für Gesundheit und Soziales Berlin, <br />
          Turmstr. 21 <br />
          10559 Berlin-Mitte
        </Typography>
      </Box>

      <Box style={{ marginBottom: '20px' }}>
        <Typography variant="h6">Tag der amtsärztlichen Überprüfung</Typography>
        <Typography>
          22.05.2024 <br />
          Die Erlaubnis zur berufsmäßigen Ausübung der Heilkunde
ohne Bestallung unter der Berufsbezeichnung Heilpraktikerin
wurde erteilt durch das Bezirksamt Tempelhof-Schöneberg von Berlin,
Abt. Jugend und Gesundheit, Gesundheitsamt –Heilpraktikerbereich–
        </Typography>
      </Box>

      <Box style={{ marginBottom: '20px' }}>
        <Typography variant="h6">Berufsverbandsmitgliedschaft und Berufsordnung</Typography>
        <Typography>
        Verband Deutscher Heilpraktiker e.V. <br />
          Mitglied-Nr.: 13604<br />
          Berufsordnung: <a href="https://www.vdh-heilpraktiker.de/fileadmin/nutzerdateien/user_upload/BOH_Heft.pdf" target="_blank" rel="noopener noreferrer">
          https://www.vdh-heilpraktiker.de/fileadmin/nutzerdateien/user_upload/BOH_Heft.pdf
          </a>
        </Typography>
      </Box>

      <Box style={{ marginBottom: '20px' }}>
        <Typography variant="h6">Berufshaftpflichtversicherung</Typography>
        <Typography>
          Versicherung: Continentale Sachversicherung AG <br />
          Versicherungsnummer: 171584143 <br />
          Geltungsbereich: Deutschland
        </Typography>
      </Box>

      <Box style={{ marginBottom: '20px' }}>
        <Typography variant="h6">Berufsrechtliche Regelungen</Typography>
        <Typography>
          Es gelten die Regelungen des Heilpraktikergesetzes (HeilprG) und der 1.
          Durchführungsverordnung zum Heilpraktikergesetz. Diese sind
          einsehbar unter: <br />
          <a href="https://www.gesetze-im-internet.de/heilprg/BJNR002510939.html" target="_blank" rel="noopener noreferrer">
            https://www.gesetze-im-internet.de/heilprg/BJNR002510939.html
          </a>
        </Typography>
      </Box>

      <Box style={{ marginBottom: '20px' }}>
        <Typography variant="h6">Umsatzsteuer-Identifikationsnummer</Typography>
        <Typography>
Heilkundliche Tätigkeiten sind gemäß § 4 Nr. 14 UStG von der Umsatzsteuer befreit.
        </Typography>
      </Box>

      <Box style={{ marginBottom: '20px' }}>
        <Typography variant="h6">Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV</Typography>
        <Typography>
        Sylwia Spiller <br />
        Riemeisterstraße 39 a3 <br />
        14169 Berlin
        </Typography>
      </Box>

      <Box style={{ marginBottom: '20px' }}>
        <Typography variant="h6">Haftungsausschluss (Disclaimer)</Typography>
        <Typography>
          <strong>Haftung für Inhalte</strong> <br />
          Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit,
          Vollständigkeit und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen.
        </Typography>
        <Typography style={{ marginTop: '10px' }}>
          <strong>Haftung für Links</strong> <br />
          Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte wir keinen Einfluss haben.
          Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen.
        </Typography>
      </Box>

      <Box>
        <Typography variant="h6">Streitschlichtung</Typography>
        <Typography>
          Die EU-Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <br />
          <a href="https://ec.europa.eu/consumers/odr" target="_blank" rel="noopener noreferrer">
            https://ec.europa.eu/consumers/odr
          </a>
        </Typography>
        <Typography>
          Unsere E-Mail-Adresse finden Sie oben im Impressum. Wir sind nicht bereit oder verpflichtet, an
          Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.
        </Typography>
      </Box>
    </Container>
  );
};

export default Impressum;
