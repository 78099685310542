import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import sylwia from "../assets/Sylwia.jpg";

function ÜberMich() {
  return (
    <Container style={{ padding: '50px' }}>
      {/* Heading */}
      <Typography variant="h4" align="center" style={{ marginBottom: '20px' }}>
        Über mich
      </Typography>

      {/* Image */}
      <Box display="flex" justifyContent="center" marginBottom="20px">
        <img 
          src={sylwia} // Replace with the actual path to your image
          alt="Über mich"
          style={{ width: '250px', height: 'auto', borderRadius: '8px', boxShadow: '0 2px 10px rgba(0,0,0,0.2)', }} // Fixed size with auto height
        />
      </Box>

      {/* Short Biography */}
      <Typography variant="body1" align="center">
        Ich bin Sylwia Spiller, Heilpraktiker mit Leidenschaft für natürliche Heilmethoden.
      </Typography>
      <Typography variant="body1" align="center" style={{ marginTop: '10px' }}>
        Meine Philosophie ist es, Körper, Geist und Seele in Einklang zu bringen.
      </Typography>
    </Container>
  );
}

export default ÜberMich;