import React from 'react';
import { Container, Grid as Grid2, Typography, Card, CardContent } from '@mui/material';

function Leistungen() {
  const services = [
    { title: "Ohrakupunktur", description: "Gezielte Stimulation zur Schmerzlinderung, Stressreduktion und begleitend bei Raucherentwöhnung und Gewichtsreduktion." },
    { title: "Infusionstherapien", description: "Vitalstoffzufuhr zur Unterstützung des Immunsystems und Wohlbefindens." },
    { title: "Dorn-/Preusstherapie", description: "Sanfte Korrektur von Wirbel- und Gelenkblockaden." },
    { title: "Reflexzonentherapie", description: "Aktivierung der Selbstheilung durch Reflexzonenmassage der Hände und Füße." },
];

  return (
    <Container className="container" style={{ padding: '50px' }}>
      <Typography variant="h4" align="center" style={{ marginBottom: '20px' }}>
        Unsere Leistungen
      </Typography>
      <Grid2 container spacing={4} justifyContent="center"> {/* Center align the Grid2 items */}
        {services.map((service) => (
          <Grid2 item xs={12} sm={6} md={3} key={service.title}>
            <Card style={{ marginTop: '20px', borderRadius: '15px', boxShadow: '0 2px 10px rgba(0,0,0,0.2)' }}>
              <CardContent>
                <Typography variant="h6" align="center">{service.title}</Typography> {/* Center align title */}
                <Typography variant="body2" align="center" style={{ marginTop: '10px', color: 'grey' }}>
                  {service.description}
                </Typography> {/* Center align description */}
              </CardContent>
            </Card>
          </Grid2>
        ))}
      </Grid2>
    </Container>
  );
}

export default Leistungen;
