import React from 'react';
import { Container, Typography, Card, CardContent, Link } from '@mui/material';

function Kontakt() {
  return (
    <Container style={{ padding: '50px' }}>
      {/* Title */}
      <Typography variant="h4" align="center" style={{ marginBottom: '20px' }}>
        Kontakt
      </Typography>

      {/* Combined Card for Contact Information and General Inquiry */}
      <Card style={{ marginTop: '20px', borderRadius: '15px', boxShadow: '0 2px 10px rgba(0,0,0,0.2)' }}>
        <CardContent>
          <Typography variant="h6">Kontaktmöglichkeiten & Terminvereinbarung</Typography>

          <Typography variant="body1" style={{ marginTop: '10px' }}>
            Die Terminvergabe erfolgt auf Anfrage, telefonisch oder per E-Mail.
          </Typography>

          <Typography variant="body1" style={{ marginTop: '10px' }}>
            Telefon:
            <Link href="tel:+49 15560759736" color="inherit" style={{ marginLeft: '5px' }}>
            +49 15560759736
            </Link>
          </Typography>
          
          <Typography variant="body1" style={{ marginTop: '10px' }}>
            Mail:
            <Link href="mailto:kontakt@heilpraxis-spiller.de" color="inherit" style={{ marginLeft: '5px' }}>
              kontakt@heilpraxis-spiller.de
            </Link>
          </Typography>
        </CardContent>
      </Card>
    </Container>
  );
}

export default Kontakt;
