// src/Datenschutzerklaerung.js
import React from 'react';
import { Container, Typography, Box } from '@mui/material';

const Datenschutzerklaerung = () => {
  return (
    <Container style={{ padding: '40px' }}>
      <Typography variant="h4" align="center" gutterBottom>
        Datenschutzerklärung
      </Typography>

      <Box style={{ marginBottom: '20px' }}>
        <Typography>
          Der Schutz Ihrer persönlichen Daten ist uns ein wichtiges Anliegen. Nachfolgend informieren wir Sie darüber, wie auf unserer Website mit Ihren Daten umgegangen wird.
        </Typography>
      </Box>

      <Box style={{ marginBottom: '20px' }}>
        <Typography variant="h6">1. Verantwortlicher für die Datenverarbeitung</Typography>
        <Typography>
          Verantwortlich für die Datenverarbeitung auf dieser Website ist:<br />
          <strong>Sylwia Spiller</strong><br />
          Riemeisterstraße 39 a<br />
          +49 15560759736<br />
          kontakt@heilpraxis-spiller.de
        </Typography>
      </Box>

      <Box style={{ marginBottom: '20px' }}>
        <Typography variant="h6">2. Erhebung und Speicherung personenbezogener Daten</Typography>
        <Typography>
          Beim Besuch dieser Website werden keine personenbezogenen Daten erhoben, gespeichert oder verarbeitet. 
          Wir verwenden keine Tracking-Tools, Analyse-Software oder Cookies und speichern keine IP-Adressen oder sonstigen personenbezogenen Daten der Besucher.
        </Typography>
      </Box>

      <Box style={{ marginBottom: '20px' }}>
        <Typography variant="h6">3. Bereitstellung der Website und Server-Logfiles</Typography>
        <Typography>
          Zum Betrieb der Website greifen wir auf die Infrastruktur eines Hosting-Anbieters zurück. Dieser speichert keine personenbezogenen Daten in Form von Server-Logfiles. 
          Ein Zugriff auf personenbezogene Daten erfolgt ausschließlich, wenn dies gesetzlich vorgeschrieben ist oder bei Missbrauchs- und Sicherheitsvorfällen (z. B. Cyberangriffe) eine Pflicht zur Aufklärung besteht.
        </Typography>
      </Box>

      <Box style={{ marginBottom: '20px' }}>
        <Typography variant="h6">4. Externe Links</Typography>
        <Typography>
          Diese Website kann Links zu anderen Websites enthalten, auf deren Inhalte wir keinen Einfluss haben. 
          Für den Datenschutz und die Inhalte externer Seiten übernehmen wir keine Verantwortung. 
          Bitte informieren Sie sich auf den entsprechenden Seiten über deren Datenschutzrichtlinien.
        </Typography>
      </Box>

      <Box style={{ marginBottom: '20px' }}>
        <Typography variant="h6">5. Rechte der betroffenen Person</Typography>
        <Typography>
          Da auf dieser Website keine personenbezogenen Daten erhoben werden, gibt es keine Rechte auf Auskunft, Berichtigung oder Löschung im Hinblick auf diese Website.
        </Typography>
      </Box>

      <Box style={{ marginBottom: '20px' }}>
        <Typography variant="h6">6. Änderungen der Datenschutzerklärung</Typography>
        <Typography>
          Diese Datenschutzerklärung wird bei Bedarf aktualisiert, um rechtliche oder technische Änderungen zu berücksichtigen. 
          Bitte überprüfen Sie sie regelmäßig auf Änderungen.
        </Typography>
      </Box>

      <Box style={{ marginBottom: '20px' }}>
        <Typography variant="h6">7. Kontakt</Typography>
        <Typography>
          Bei Fragen zum Datenschutz wenden Sie sich bitte an:<br />
          <strong>Sylwia Spiller</strong><br />
          +49 15560759736<br />
          kontakt@heilpraxis-spiller.de
        </Typography>
      </Box>
    </Container>
  );
};

export default Datenschutzerklaerung;
